.App {
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  max-height: var(--tg-viewport-height);
  /* min-height: var(--tg-viewport-height); */
  text-align: center;
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}
